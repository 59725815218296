<template>
  <div>
    <el-form
      ref="form"
      label-width="120px"
      class="h-full overflow-y-auto overflow-x-hidden editWrapAd"
      :model="adsMsg"
      :rules="rules"
      :disabled="currentData.disabled"
    >
      <el-form-item
        label="广告名称"
        prop="name"
      >
        <el-input
          v-model="adsMsg.name"
          placeholder="广告名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="广告状态"
        prop="status"
      >
        <el-switch
          v-model="adsMsg.status"
          active-text="开启"
          inactive-text="暂停"
          active-value="ENABLED"
          inactive-value="PAUSED"
        >
        </el-switch>
      </el-form-item>
      <el-form-item
        label="广告类型"
        prop=""
      >
        <el-tabs
          type="border-card"
          v-model="adsMsg.type"
          @tab-click="changeType"
          disabled
        >
          <el-tab-pane
            label="图片"
            name="DEMAND_GEN_MULTI_ASSET_AD"
          >
          </el-tab-pane>
          <el-tab-pane
            label="视频"
            name="DEMAND_GEN_VIDEO_RESPONSIVE_AD"
          >
          </el-tab-pane>
          <div>
            <!-- 图片 -->
            <el-form-item
              class="flex p-4"
              prop="demandGenAd.multiImages"
              v-show="adsMsg.type == 'DEMAND_GEN_MULTI_ASSET_AD'"
            >
              <UploadImage
                :value.sync="adsMsg.demandGenAd.multiImages"
                :currentAccount="createAdAccount"
                :disabled="currentData.disabled"
              />
            </el-form-item>
            <!-- 视频 -->
            <el-form-item
              class="flex p-4"
              prop="demandGenAd.videoAssets"
              v-show="adsMsg.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD'"
            >
              <UploadVideo
                v-model="adsMsg.demandGenAd.videoAssets"
                :currentAccount="createAdAccount"
                @input="(val) => getCreativeValue(val, 'videoAssets')"
                :disabled="currentData.disabled"
              />
            </el-form-item>
          </div>
        </el-tabs>
      </el-form-item>
      <el-form-item
        label="徽标"
        prop="demandGenAd.logoImages"
      >
        <UploadLogo
          v-model="adsMsg.demandGenAd.logoImages"
          :currentAccount="createAdAccount"
          :type="adsMsg.type"
          :disabled="currentData.disabled"
        />
      </el-form-item>
      <el-form-item
        label="标题"
        prop="demandGenAd.headlines"
        class="titleWrap"
      >
        <CreativeTitle
          v-model="adsMsg.demandGenAd.headlines"
          :maxlength="40"
          type="标题"
        />
      </el-form-item>
      <el-form-item
        label="长标题"
        prop="demandGenAd.longHeadlines"
        v-show="adsMsg.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD'"
        class="titleWrap"
      >
        <CreativeTitle
          type="长标题"
          v-model="adsMsg.demandGenAd.longHeadlines"
          :maxlength="90"
          @input="(val) => getCreativeValue(val, 'longHeadlines')"
        />
      </el-form-item>
      <el-form-item
        label="描述"
        prop="demandGenAd.descriptions"
        class="titleWrap"
      >
        <CreativeTitle
          type="描述"
          v-model="adsMsg.demandGenAd.descriptions"
          :maxlength="90"
        />
      </el-form-item>
      <el-form-item
        label="行动号召"
        prop="demandGenAd.callToActions"
        v-show="adsMsg.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD'"
      >
        <CallToAction
          type="video"
          v-model="adsMsg.demandGenAd.callToActions"
          :enumConstants="enumConstants"
          @input="(val) => getCreativeValue(val, 'callToActions')"
        />
      </el-form-item>
      <el-form-item
        label="行动号召"
        prop="demandGenAd.callToAction"
        v-show="adsMsg.type != 'DEMAND_GEN_VIDEO_RESPONSIVE_AD'"
      >
        <CallToAction
          type="img"
          v-model="adsMsg.demandGenAd.callToAction"
          :enumConstants="enumConstants"
          @input="(val) => getCreativeValue(val, 'callToAction')"
        />
      </el-form-item>
      <el-form-item
        label="商家名称"
        prop="demandGenAd.businessName"
      >
        <el-input
          v-model="adsMsg.demandGenAd.businessName"
          class="flex-1"
        ></el-input>
      </el-form-item>
      <el-form></el-form>
    </el-form>
  </div>
</template>
<script>
import CreativeTitle from '@/views/google/create/components/creatives/CreativeTitle/index.vue';
import CallToAction from '@/views/google/create/components/creatives/CallToAction/index.vue';
import UploadImage from '@/views/google/create/components/creatives/UploadImage/index.vue';
import UploadLogo from '@/views/google/create/components/creatives/UploadLogo/index.vue';
import UploadVideo from '@/views/google/create/components/creatives/UploadVideo/index.vue';
import { callToActionList, callToActionMap } from '@/views/google/create/index';
import { adDetail, updateAd } from '@/api/google/create';
import { mapState } from 'vuex';
import {
  validateWord,
  validatorVideo,
  validatorMedia,
  validatorCollMedia,
} from '@/views/google/create/components/creatives/index';
const baseRules = {
  name: [
    {
      required: true,
      message: '广告名称必填',
    },
  ],
  status: [{ required: true }],
  'demandGenAd.logoImages': [
    {
      required: true,
      validator: validatorCollMedia,
    },
  ],
  'demandGenAd.headlines': [
    {
      required: true,
      validator: (rule, value, callback) => validateWord('标题', 40, rule, value, callback),
    },
  ],
  'demandGenAd.descriptions': [
    {
      required: true,
      validator: (rule, value, callback) => validateWord('描述', 90, rule, value, callback),
    },
  ],
  'demandGenAd.businessName': [
    {
      required: true,
      message: '商家名称必填',
    },
  ],
};
// 图片视频校验
export const imgRules = {
  ...baseRules,
  'demandGenAd.multiImages': [
    {
      validator: validatorMedia,
      required: true,
    },
  ],
};
// 图片视频校验
export const videoRules = {
  ...baseRules,
  'demandGenAd.longHeadlines': [
    {
      required: true,
      validator: (rule, value, callback) => validateWord('视频长标题', 90, rule, value, callback),
    },
  ],
  'demandGenAd.videoAssets': [
    {
      validator: validatorVideo,
      required: true,
      trigger: 'change',
    },
  ],
};
export default {
  props: {
    currentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      adsMsg: {
        demandGenAd: {},
      },
      enumConstants: {},
      videoRules,
      imgRules,
    };
  },
  components: {
    CreativeTitle,
    CallToAction,
    UploadImage,
    UploadLogo,
    UploadVideo,
  },
  computed: {
    ...mapState('google', ['createAdAccount']),
    rules() {
      if (this.adsMsg.type == 'DEMAND_GEN_VIDEO_RESPONSIVE_AD') {
        return this.videoRules;
      } else {
        return this.imgRules;
      }
    },
  },
  watch: {
    currentData: {
      handler() {
        this.adsMsg = this.currentData;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    /**校验广告 */
    checkForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          console.log(valid);
          if (!valid) {
            return resolve(false);
          }
          return resolve(true);
        });
      });
    },
    /**修改广告样式 */
    changeType(v) {
      console.log(v);
      if (v.name == 'DEMAND_GEN_MULTI_ASSET_AD') {
        this.$set(this.adsMsg.demandGenAd, '@class', '.DemandGenMultiAssetAd');
      } else {
        this.$set(this.adsMsg.demandGenAd, '@class', '.DemandGenVideoResponsiveAd');
      }
    },
    // 设置广告内容
    getCreativeValue(value, key) {
      this.$set(this.adsMsg.demandGenAd, key, value);
    },
  },
};
</script>
<style lang="scss">
.editWrapAd .titleWrap .el-input {
  width: 90%;
}
</style>
