<template>
  <div>
    <el-input
      class="filter-tree-input"
      v-model="filterText"
      clearable
      size="small"
      placeholder="请输入关键字进行过滤"
      @input="handleInputFilter"
    />
    <el-tree
      class="mt-2"
      ref="tree"
      :data="treeData"
      default-expand-all
      node-key="index"
      :props="defaultProps"
      :filter-node-method="filterNode"
      :default-checked-keys="['0']"
      :highlight-current="true"
      :check-strictly="true"
      :expand-on-click-node="false"
      @node-click="handleNodeClick"
    >
      <div
        slot-scope="{ node, data }"
        :class="['ellipsis', 'nameWrap', { 'selected-node': checkedNodes.includes(data.id) }]"
      >
        <el-tooltip
          :content="node.label"
          placement="right"
          :disabled="node.label.length <= 25"
        >
          <div class="ellipsis py-1 name">{{ node.label }}</div>
        </el-tooltip>
      </div>
    </el-tree>
  </div>
</template>
<script>
export default {
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterText: '',
      checkedNodes: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
    };
  },
  methods: {
    /**筛选 */
    filterNode(value, data) {
      if (!value) return true; // 如果没有过滤条件，则显示所有节点
      return data.name.indexOf(value) !== -1; // 根据过滤条件判断是否显示节点
    },
    /**输入框输入 */
    handleInputFilter() {
      this.$refs.tree.filter(this.filterText); // 当输入框内容变化时调用filter方法1
    },
    /**点击节点 */
    handleNodeClick(data, node, nodeComponent) {
      console.log(data);
      this.commonChange(data);
      this.highlightNode(data.index);
    },
    highlightNode(key) {
      this.$refs.tree.setCheckedKeys([key]);
    },
    /**设置默认值 */
    setDefaultData(data) {
      this.selectedLevel = data.level;
      this.$emit('setCurrentData', data);
    },
    commonChange(data) {
      console.log(data);
      //   this.$emit('setCurrentData', data);
      this.$emit('validateData', this.selectedLevel, (isValid) => {
        if (isValid) {
          // 如果校验不通过，恢复到之前选中的节点
          this.selectedLevel = data.level;
          this.$emit('setCurrentData', data);
        } else {
        }
      });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
::v-deep .el-tree-node__content {
  padding-right: 10px;
  line-height: 1.8;
  height: auto;
  .el-tree-node {
    white-space: wrap;
  }
  .nameWrap {
  }
  .name {
  }
}
</style>
