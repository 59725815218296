<template>
  <div>
    <el-form
      label-position="right"
      :model="ruleForm"
      :rules="rules"
      :disabled="currentData.disabled"
      ref="ruleForm"
      label-width="130px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="广告系列名称："
        prop="name"
      >
        <el-input
          v-model="ruleForm.name"
          clearable
        ></el-input></el-form-item
      ><el-form-item
        label="状态："
        prop="status"
      >
        <el-switch
          v-model="ruleForm.status"
          active-text="开启"
          inactive-text="暂停"
          active-value="ENABLED"
          inactive-value="PAUSED"
        ></el-switch>
      </el-form-item>
      <el-form-item
        label="系列预算"
        prop="budgetAmount"
      >
        <el-input
          v-model="ruleForm.budgetAmount"
          style="width: 320px"
          type="number"
          placeholder="请输入预算金额"
          @blur="changeBudget"
        >
          <el-select
            v-model="ruleForm.budgetType"
            placeholder=""
            slot="prepend"
            style="width: 120px"
          >
            <el-option
              v-for="item in budgetTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <div class="flex">
        <el-form-item
          label="排期"
          prop="startDate"
        >
          <el-date-picker
            v-model="ruleForm.startDate"
            type="datetime"
            placeholder="开始时间"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            class="w-full"
            @change="changeStartTime"
          ></el-date-picker>
        </el-form-item>
        <span class="mx-2">~</span>
        <el-form-item
          label=""
          label-width="0"
          prop="endDate"
        >
          <el-date-picker
            v-model="ruleForm.endDate"
            type="datetime"
            placeholder="结束时间"
            @change="selectEndTime"
            :picker-options="pickerOptions1"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { budgetTypes } from '@/views/google/create/index';
import moment from 'moment';
export default {
  // 组件名称
  name: 'campaign',
  // 组件参数 接收来自父组件的数据
  props: {
    currentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      budgetTypes: budgetTypes,
      checkData: {
        adsetBudgetMin: 5,
        adsetBudgetMax: 10000000,
        bidMin: 0.01,
        bidMax: 1000000,
      },
      ruleForm: {},
      //   ruleForm: {
      //     name: '',
      //     status: 'ACTIVE',
      //   },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      pickerOptions1: {
        disabledDate: this.disabledDate,
      },
      rules: {
        name: [
          { required: true, message: '请输入广告系列名称', trigger: ['blur', 'change'] },
          { min: 1, max: 375, message: '长度在 1 到 375 个字符', trigger: ['blur', 'change'] },
        ],
        budgetAmount: [
          {
            validator: (rule, value, callback) => {
              if (!value) callback('预算不能为空');
              if (this.ruleForm.budgetType == 'LIFE_TIME') {
                if (this.ruleForm.startDate && this.ruleForm.endDate && value < this.getBudgetMin()) {
                  return callback(`预算在${this.getBudgetMin()}～${this.checkData.adsetBudgetMax}区间`);
                }
              } else if (value && (value < this.checkData.adsetBudgetMin || value > this.checkData.adsetBudgetMax)) {
                return callback(`预算在${this.checkData.adsetBudgetMin}～${this.checkData.adsetBudgetMax}区间`);
              }
              callback();
            },
            required: true,
            trigger: ['blur', 'change'],
          },
        ],
        startDate: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback('开始时间必填');
              }
              if (this.ruleForm.budgetType == 'LIFE_TIME') {
                if (new Date(value).getTime() > new Date(this.ruleForm.endDate).getTime()) {
                  return callback('开始时间不能大于结束时间');
                }
              }
              callback();
            },
            trigger: ['change', 'blur'],
          },
        ],
        endDate: [
          {
            validator: (rule, value, callback) => {
              if (this.ruleForm.budgetType == 'LIFE_TIME') {
                if (!value) {
                  callback('预算类型是总预算的时候,结束时间必填');
                }
                if (new Date(value).getTime() < new Date(this.ruleForm.startDate).getTime()) {
                  return callback('结束时间不能小于开始时间');
                }
              }
              callback();
            },
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  watch: {
    currentData: {
      handler() {
        this.ruleForm = this.currentData;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    /**对外抛出验证 */
    checkForm() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          console.log(valid);
          if (!valid) {
            return resolve(false);
          }
          return resolve(true);
        });
      });
    },
    // 根据结束日期，计算最小预算
    getBudgetMin() {
      let start = moment(this.ruleForm.startDate).format('YYYY-MM-DD');
      let end = moment(this.ruleForm.endDate).format('YYYY-MM-DD');
      let days = moment(end).diff(start, 'days');
      return this.checkData.adsetBudgetMin * (days + 1);
    },
    // 修改预算
    changeBudget(v) {
      console.log(v);
      this.ruleForm.budgetAmount = (this.ruleForm.budgetAmount / 1).toFixed(2);
      //   this.splitView();
    },
    // 修改开始时间
    changeStartTime() {
      this.$refs.ruleForm.validateField('budgetAmount');
    },
    // 总预算设置结束日期的时候校验预算
    selectEndTime() {
      this.$refs.ruleForm.validateField('budgetAmount');
      //   this.splitView();
    },
    disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.ruleForm.startDate).getTime();
    },
  },
};
</script>
<style lang="scss" scoped></style>
